<template>
  <div class="containerBar">
    <div> {{ percent }}% </div>
    <div class="loading-bar">
      <div class="percentage" :style="{ width: percent + '%' }"></div>
    </div>
    uploading
    <div class="table-row table-row-empty">
      <div class="table-column table-column-full text-center">
        <span class="spinner spinner-black"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProgressBar',
  data() {
    return {
      percentage: 0
    };
  },
  computed: {
    ...mapState('outboundCaller', ['uploadLoading', 'lengthOfUpload', 'sizeOfUpload']),
    percent: {
      get() {
        const value = ((this.sizeOfUpload / (this.lengthOfUpload / 5000)) * 100).toFixed();
        if (value >= 100) {
          return 100;
        }
        return ((this.sizeOfUpload / (this.lengthOfUpload / 5000)) * 100).toFixed();
      },
      set(value) {
        if (value <= 100) {
          return value;
        }
        return 100;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.containerBar {
  text-align: center;
  font-size: 3rem;
  color: #555;
  width: 100%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f4;
  border-radius: 20px;
}

.loading-bar {
  position: relative;
  width: 400px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  // box-shadow: inset 0 1px 2px rgba($color: #000, $alpha: 0.4), 0 -1px 1px #fff, 0 1px 0 #fff;
}
.percentage {
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  display: block;
  height: 100%;
  border-radius: 15px;
  background-color: #a5df41;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba($color: #fff, $alpha: 0.15) 25%,
    transparent 50%,
    transparent 50%,
    rgba($color: #fff, $alpha: 0.15) 50%,
    rgba($color: #fff, $alpha: 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
</style>
