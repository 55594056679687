<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <order-report-box
      :columns="columns"
      :rows="calendarList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showUpload="ifAdmin()"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :searchItems="searchItems"
      downloadText="Download Calendar"
      uploadText="Upload"
      emptyText="No Data found."
      @show-upload="onUpload"
      @delete="onDelete"
    >
    </order-report-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import orderReportBox from '../../components/orderReportBox.vue';

export default {
  name: 'TargetList',
  components: {
    orderReportBox
  },
  metaInfo() {
    return {
      title: 'Calendar List',
      meta: [
        {
          name: 'description',
          content: `Manage Calendar`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listCalendar({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(2, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listCalendar({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listCalendar({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Campaign List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'region',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Campaign Name',
          class: { 'text-center': true },
          textKey: 'campaignName',
          width: '19%'
        },
        {
          type: 'string',
          headerText: 'Campaign startDate',
          class: { 'text-center': true },
          textKey: 'campaignStartDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Campaign EndDate',
          class: { 'text-center': true },
          textKey: 'campaignEndDate',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Catalogue Creation By',
          class: { 'text-center': true },
          textKey: 'catalogueCreationBy',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Catalogue Approval By',
          class: { 'text-center': true },
          textKey: 'catalogueApprovalBy',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Catalogue Live By',
          class: { 'text-center': true },
          textKey: 'catalogueLiveBy',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '14%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Campaign Name', 'Region']
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('calendar', ['loading', 'baseUrl', 'calendarList', 'pagination', 'downloadCalendarList'])
  },
  watch: {
    downloadcalendarList(newValue) {
      if (newValue.length) {
        let csv = 'Row Num,Store Id,Target,Date,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Targets.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('calendar', ['listCalendar', 'deleteOne', 'upload', 'download']),
    ifAdmin() {
      const isAdmin = this.user.role === 99;
      const user = this.user.roles.map(role => role.permissions);
      const newUser = user.map(permission => permission.map(permission => permission.name));
      const filteredUser = newUser.filter(permission => permission.includes('APPROVE_CATALOGUE'));

      if (isAdmin || filteredUser.length > 0) {
        return true;
      }
      return false;
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },
    onDelete({ row }) {
      if (this.user.role === 99) {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.id
            });
          }
        });
      } else {
        Vue.swal({
          title: 'Access Denied',
          text: "You don't have permission to delete this.",
          type: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    createCsv() {
      const csv = `Region,Campaign Name, Camapign StartDate(MM-DD-YYYY), Campaign EndDate(MM-DD-YYYY), Catalogue Creation By(MM-DD-YYYY), Catalogue Approval By(MM-DD-YYYY), Catalogue Live By(MM-DD-YYYY)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Sample.csv';
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file for Calendar',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  const values = item.reduce((acc, curr, index) => {
                    if (index === 0) {
                      acc.region = curr;
                    }
                    if (index === 1) {
                      acc.campaignName = curr;
                    }
                    if (index === 2) {
                      acc.campaignStartDate = curr;
                    }
                    if (index === 3) {
                      acc.campaignEndDate = curr;
                    }
                    if (index === 4) {
                      acc.catalogueCreationBy = curr;
                    }
                    if (index === 5) {
                      acc.catalogueApprovalBy = curr;
                    }
                    if (index === 6) {
                      acc.catalogueLiveBy = curr;
                    }
                    return acc;
                  }, {});
                  return values;
                });
                this.upload({ router, data: inputs });
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  }
};
</script>
